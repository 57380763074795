<template>
  <div v-if="showViewStat" @click="toggleShowNames" class="is-size-7 has-text-centered has-text-grey-light is-clickable mb-0 pb-0">
    <i class="fas fa-eye mr-1"></i>
    {{ views }} other team member{{views>1 ? 's':''}} on this page
    <i v-if="!showNames && views > 0" class="fas fa-chevron-right ml-1"></i>
    <i v-if="showNames && views > 0" class="fas fa-chevron-down ml-1"></i>
    <div v-if="showNames" class="container my-0 py-0 has-text-weight-semibold">
      {{ names }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewsStat',
  props: {
    pageDetails: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showNames: false
    }
  },
  methods: {
    toggleShowNames () {
      this.showNames = !this.showNames
    }
  },
  computed: {
    showViewStat() {
      return this.$store.state.adventureTeam && this.$store.state.adventureTeam.teamMembers.length > 1 && this.views > 0
    },
    key() {
      return `${this.pageDetails.pageName}${this.pageDetails.stage}${this.pageDetails.challenge}`
    },
    views() {
      if(this.key && this.$store.state.adventureView) {
        if(this.$store.state.adventureView.stats[this.key]){
          return this.$store.state.adventureView.stats[this.key]
        } else {
          return 0
        }
      }
      return 0
    },
    names() {
      if(this.key && this.$store.state.adventureView) {
        return this.$store.state.adventureView.views.filter(el => {
          return el.key === this.key
        }).map(el => {
          return el.userName
        }).join(', ')
      }
      return 0
    }
  }
}
</script>