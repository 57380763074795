<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div v-if="this.adventureTeam && this.adventureTeam.hasScannableItems" class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'scavenge'}"/>
        <div class="container has-text-centered pt-4 pb-2">
          <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ $store.state.uiMods.scan }}
          </div>
        </div>
        <div class="container has-text-centered">
        <div class="is-size-6 has-text-grey mb-2 px-4">
          {{ $store.state.uiMods.scanSubtext ?
          $store.state.uiMods.scanSubtext :
          `Scan for ${adventureTeam.hasAccessCodeTask ? 'tasks and ' : ''} items by scanning or typing in a code.`
          }}
        </div>
          <FormInput
          :size="'medium'"
          :title="'Type manually or use your camera'"
          :type="'qrbarcode'"
          :value="code"
          v-on:update="code = $event"
          v-on:valueScanned="valueScanned($event)"
          />
          <div v-if="showItemFoundMessage" class="container has-text-centered mt-2">
            <b>Something found!</b> Go to your <i class="fas fa-sm fa-box ml-1"></i> Inventory to see it.
          </div>
          <div v-if="showItemNotFoundMessage" class="container has-text-centered my-2">
            Nothing found, try another code.
          </div>
          <a
          v-if="!showItemFoundMessage"
          @click="scavenge(false)"
          class="button has-text-weight-semibold is-large is-primary mt-2"
          v-bind:class="{'is-loading': loading}"
          :disabled="!allowScavengeCode"
          >
            <i class="fas fa-search-location mr-2"></i>
            Scan
          </a>
          <a
          v-if="showItemFoundMessage"
          @click="$router.push({ name: 'Inventory' })"
          class="button has-text-weight-semibold is-large is-success"
          >
            <i class="fas fa-search-location mr-2"></i>
            Go to Inventory
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import ViewsStat from '@/components/ViewsStat.vue'
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'Scavenge',
  components: {
    CardNavigation,
    ViewsStat,
    FormInput
  },
  data(){
    return {
      enterCode: null,
      code: null,
      showItemFoundMessage: false,
      showItemNotFoundMessage: false,
      loading: false
    }
  },
  methods: {
    valueScanned(code) {
      this.code = code
      if (this.code.includes('pyts.link/qr/')) {
        this.code = this.code.substring(this.code.lastIndexOf('/') + 1);
      }
      this.scavenge(true)
    },
    scavenge(isValueScanned) {

      if(isValueScanned !== null) {
        const validQrCodes = [
          'tfam3031', 'tfam2930', 'tfam2527', 'tfam2428', 'tfam2223',
          'tfam2126', 'tfam1820', 'tfam1516', 'tfam1319', 'tfam1214',
          'tfam1017', 'tfam0901'
        ]
        if(validQrCodes.includes(this.code)) {
          if (new Date() < new Date('2024-05-13')) {
            alert('Unlock on May 13 and onwards.')
            return
          }

          const hazelQrCodeMappings = JSON.parse(localStorage.getItem(this.$store.state.teamCode + 'hazelQrCodeMappings') || '{}')
          if(Object.prototype.hasOwnProperty.call(hazelQrCodeMappings, this.code)) {
            this.code = hazelQrCodeMappings[this.code]
          } else {
            const previouslyScannedCodes = Object.values(hazelQrCodeMappings)
            const remainingQrCodes = validQrCodes.filter(qrCode => !previouslyScannedCodes.includes(qrCode))
            if(remainingQrCodes.length > 0) {
              const randomIndex = Math.floor(Math.random() * remainingQrCodes.length)
              const randomizedCode = remainingQrCodes[randomIndex]
              hazelQrCodeMappings[this.code] = randomizedCode
              this.code = randomizedCode
              localStorage.setItem(this.$store.state.teamCode + 'hazelQrCodeMappings', JSON.stringify(hazelQrCodeMappings))
            }
          }
        }
      }

      if(
        isValueScanned ||
        (!this.loading && (this.allowScavengeLocation || this.allowScavengeCode))
      ){
        for (const [index, challenge] of this.adventureTeam.stageDetails.challenges.entries()) {
          if(challenge.access_code && challenge.access_code === this.code){
            this.$store.commit('updateLatestAccessCode', this.code)
            this.$router.push({
                name: 'Challenge',
                params: {
                  stageIndex: this.$store.state.currentStage,
                  challengeIndex: index+1
                }
              }
            )
            return
          }
        }
        this.showItemFoundMessage = false
        this.showItemNotFoundMessage = false
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        masterFunction({
          methodName: 'search',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          scanCode: this.code,
          position: null
        }).then(res => {
          this.loading = false
          if(res.data.foundItems.length > 0) {
            this.showItemFoundMessage = true
          } else {
            if(res.data.foundItems.length === 0) {
              this.showItemNotFoundMessage = true
            }
          }
        }).catch(err => {
          this.loading = false
        })
      }
    },
  },
  watch: {
    code() {
      this.showItemFoundMessage = false
      this.showItemNotFoundMessage = false
    },
    userLocation() {
      this.showItemFoundMessage = false
      this.showItemNotFoundMessage = false
    }
  },
  computed: {
    userLocation() {
      return this.$store.state.userLocation
    },
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    allowScavengeCode() {
      return !!this.code
    }
  },
  mounted() {
    if (this.adventureTeam && !this.adventureTeam.hasScannableItems) {
      this.$router.push({ name: 'Stage' })
    }

    document.querySelectorAll('img').forEach(item => {
      item.addEventListener('click', event => {
        this.$store.commit('showPhotoViewer', event.srcElement.dataset.url)
      })
    })

    this.$store.dispatch('enterPage', {
      pageName: 'scavenge'
    })
  }
}
</script>
